<template>
    <div class="card">
        <div class="card-content">
            <div class="title">
                <b>{{ this.case.name }}</b>
            </div>
            <div>{{ this.case.description }}</div>
            <p>${{ this.case.price }}</p>
        </div>
        <div class="r-quantity">
            <button class="num-sign" @click="this.numberChange(-1)">-</button>
            <span class="quantity" placeholder="輸入數量">
                {{ this.case.quantity }}</span
            >
            <button class="num-sign" @click="this.numberChange(1)">+</button>
        </div>
    </div>
</template>
<style>
.r-quantity {
    display: flex;
    justify-content: flex-end;
    width: 200px;
    margin: 0 10px 10px 0;
}
.quantity {
    padding: 0 10px;
}
.num-sign {
    width: 25px;
    height: 25px;
    border: 2px solid #cccccc;
    border-radius: 50%;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    font-size: large;
}
.title {
    margin: 20px 10px 10px auto;
}
.card {
    border: 2px solid #cccccc;
    margin: 10px;
}
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.card-content {
    padding: 2px 16px;
}
</style>

<script>
export default {
    props: {
        case: {
            // name, content, price, quantity
            type: Object,
        },
    },

    computed: {
        inputValue: {
            get() {
                return this.case.quantity;
            },
            set(val) {
                this.$emit("update", val);
            },
        },
    },
    methods: {
        numberChange(n) {
            if (n > 0 || (n < 0 && this.inputValue > 0)) {
                this.inputValue += n;
            }
        },
    },
    mounted() {
        console.log(this.case.quantity);
    },
};
</script>
