<script>
//import booksData from "../assets/json/testBook.json";
import axios from "axios";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
} from "@heroicons/vue/outline";

export default {
    data() {
        return {
            books: [],
            nowImages: [],
            nowPage: 0,
            canvasRatio: 1,
            prevRoute: null,
        };
    },
    components: {
        ChevronLeftIcon,
        ChevronRightIcon,
        XIcon,
    },
    props: {
        id: {
            type: String,
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from.name;
        });
    },
    mounted() {
        let token = localStorage.getItem("token") || this.$store.state.token;
        if (token) {
            this.$store.commit("setToken", token);
        }

        axios
            .get(
                this.$config.apiUrl + "/api/reading/" + this.$route.params.id,
                {
                    headers: { Authorization: "Bearer " + token },
                }
            )
            .then((response) => {
                if (response.data.code != "200") {
                    return;
                }

                let data = [];
                response.data.data.forEach(function (item) {
                    data.push("data:image/jpeg;base64," + item);
                });

                this.books = data;

                this.canvasFitWidowSize();
                window.addEventListener("resize", this.canvasFitWidowSize);

                this.nowImages[0] = this.books[0];
                this.getImageSizeAndPrint();
            })
            .catch((error) => {
                console.log(error);
            });
    },
    watch: {
        nowPage(newPage) {
            if (newPage < 0) {
                this.nowPage = 0;
                console.log("這是第一頁");
                this.$swal("已經是第一頁");
            } else if (newPage >= this.books.length) {
                this.nowPage = this.books.length - 1;
                console.log("這是最後一頁");
                this.$swal("已經是最後一頁");
            } else {
                this.nowImages[0] = this.books[newPage];
                this.getImageSizeAndPrint();
            }
        },
    },
    methods: {
        backToBook() {
            if (this.prevRoute) {
                this.$router.go(-1);
            } else {
                this.$router.push(`/book/${this.$route.params.id}`);
            }
        },
        canvasFitWidowSize() {
            const canvas = this.$refs.canvas;
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            this.canvasRatio = canvas.height / canvas.width;
            this.getImageSizeAndPrint();
        },
        getImageSizeAndPrint() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext("2d");
            this.nowImages.forEach((imgBase64, index) => {
                const image = new Image();
                image.src = imgBase64;
                image.onload = function () {
                    let imageRatio = image.height / image.width;
                    let dHeight = 0;
                    let dWidth = 0;
                    // 螢幕窄圖片寬
                    if (this.canvasRatio > imageRatio) {
                        dWidth = canvas.width;
                        dHeight = canvas.width * imageRatio;
                    }
                    // 螢幕寬圖片窄
                    else {
                        dHeight = canvas.height;
                        dWidth = canvas.height / imageRatio;
                    }

                    let posX = canvas.width / 2 - dWidth / 2;
                    let posY =
                        canvas.height / 2 - dHeight / 2 + dHeight * index;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(image, posX, posY, dWidth, dHeight);
                };
            });
        },
    },
};
</script>
<style lang="scss">
#comiReaderWrap {
    max-width: 100vw;
    max-height: 100vw;
    overflow: hidden;
}

.control-wrap {
    .page-arrow-btn {
        position: absolute;
        @apply inset-y-0;
        transition: transform 0.4s ease;

        &.prev-btn {
            @apply left-4;

            &:hover {
                transform: translateX(-5px);
            }
        }

        &.next-btn {
            @apply right-4;

            &:hover {
                transform: translateX(5px);
            }
        }
    }
}
</style>
<template>
    <section>
        <div id="comiReaderWrap">
            <span
                class="loader-pin animate-spin absolute inset-0 m-auto -z-10"
            ></span>
            <canvas ref="canvas"></canvas>
            <div class="control-wrap absolute inset-0">
                <button @click="nowPage--" class="page-arrow-btn prev-btn">
                    <ChevronLeftIcon class="icon-svg text-4xl text-gray" />
                </button>
                <button @click="nowPage++" class="page-arrow-btn next-btn">
                    <ChevronRightIcon class="icon-svg text-4xl text-gray" />
                </button>
                <button @click="backToBook" class="absolute right-4 top-4">
                    <XIcon class="icon-svg text-4xl text-gray" />
                </button>
                <div class="page-nav"></div>
            </div>
        </div>
    </section>
</template>
