import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/router'
import store from '@/store'
import axios from 'axios'
import '@/assets/scss/main.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)
let apiUrl = "https://zyjeng.com";
if (process.env.NODE_ENV == "development") {
    apiUrl = "http://localhost:5005"
}

app.config.globalProperties.$config = {
    apiUrl: apiUrl
}

axios.interceptors.request.use(
    config => {
        config.baseURL = apiUrl
        config.headers.Authorization = `Bearer ${store.state.token}`
        return config
    },
    error => {
        // console.log(error) 
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(function (response) {
    if (response.status != "200")
        return Promise.reject(response)

    return response
}, function (error) {

    let statusCode = error.response.status;
    if (statusCode === 401) {
        store.commit('delToken')

        let confirm = window.confirm('以下內容需登入才能瀏覽\n是否前往登入頁面?')
        if (confirm)
            router.push('/login')
    }

    if (statusCode === 500) {
        router.push('/error')
    }
    if (statusCode === 404) {
        router.push('/error')
    }

    return Promise.reject(error)
})

app.use(vue3GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID })
app.use(router)
app.use(store)
app.use(VueSweetalert2);
app.config.errorHandler = (err) => {
    alert(err);
};

app.mount('#app')

