<script>
import ComiHeader from "./ComiHeader.vue";
import ComiFooter from "./ComiFooter.vue";
export default {
  components: {
    ComiHeader,
    ComiFooter,
  },
  data() {
    return {
      isReader: false
    };
  },
  mounted() {
  },
  watch: {
    $route(to) {
      this.isReader = this.IsOnReader(to.name)
    }
  },
  methods: {
    IsOnReader(pageName) {
      if (pageName === 'PageReader') {
        return true
      } else {
        return false
      }
    }
  },
};
</script>

<template>
  <ComiHeader v-if="!isReader" />
  <main>
    <router-view></router-view>
  </main>
  <ComiFooter v-if="!isReader" />
</template>
