<script>
export default {
  mounted() {
  }
};
</script>

<template>
  <router-view></router-view>
</template>
