<template>
    <div class="card">
        <div v-if="editing">
            <!-- Edit mode -->
            <div class="flex items-center gap-4 mb-2">
                <div class="portrait group relative">
                    <img v-if="formData.picture" :src="formData.picture" />
                    <img v-else src="/img/user.jpg">
                    <input id="upload-portrait" type="file" @change="handleFileUpload" hidden>
                    <label for="upload-portrait" class="absolute inset-0 flex items-center justify-center bg-gray-800/20 group-hover:bg-gray-800/50 cursor-pointer">
                        <UploadIcon class="w-5 text-white opacity-100" />
                    </label>
                </div>
                <h2><input v-model="formData.name" placeholder="Name"></h2>
            </div>
            <textarea v-model="formData.description" :style="textareaHeight" placeholder="簡介" class="resize-none w-full"></textarea>
            <div class="absolute right-2 top-2">
                <button @click="cancelEdit">
                    <XIcon class="w-5 text-gray hover:text-primary mr-2" />
                </button>
                <button @click="saveChanges">
                    <CheckIcon class="w-5 text-gray hover:text-primary" />
                </button>
            </div>
        </div>
        <div v-else>
            <!-- Display mode -->
            <div class="flex items-center gap-4 mb-2">
                <div class="portrait">
                    <img v-if="nowData.picture" :src="'data:image/jpg;base64,' + nowData.picture" />
                    <img v-else src="/img/user.jpg">
                </div>
                <h2>{{ nowData.name }}</h2>
            </div>
            <p>{{ nowData.description }}</p>
            <button @click="editCard" class="absolute right-2 top-2">
                <PencilIcon class="w-5 text-gray hover:text-primary" />
            </button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.card {
    @apply bg-white shadow rounded-lg p-4 relative
}
.portrait {
    @apply w-16 h-16 rounded-full overflow-hidden
}
</style>
<script>
import axios from 'axios';
import { PencilIcon, CheckIcon, XIcon, UploadIcon } from '@heroicons/vue/solid'

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: {
        PencilIcon,
        CheckIcon,
        XIcon,
        UploadIcon
    },
    data() {
        return {
            editing: false,
            formData: {
                id: null,
                name: '',
                description: '',
                picture: ''
            },
            nowData: {
                name: '',
                description: '',
                picture: ''
            },
            uploadedFile: null
        };
    },
    mounted() {
        console.log('card mounted');
        this.nowData = this.data;
    },
    watch: {
        data: {
            handler(newValue) {
                // Update the form data when the prop value changes
                this.formData = { ...newValue };
                if (this.data.id < 1) {
                    this.editCard();
                }
            },
            immediate: true
        },
        'formData': {
            deep: true,
            handler(newValue) {
                // Update the form data when the prop value changes
                this.nowData = { ...newValue };
            },
        }
    },
    computed: {
        // dataCompute() {
        //     return this.formData;
        // }
        textareaHeight() {
            let value = this.formData.description
            let numberOfLineBreaks = (value.match(/\n/g) || []).length;
            let newHeight = 24 + numberOfLineBreaks * 24;
            return 'height:' + newHeight + 'px';
        }
    },
    methods: {
        editCard() {
            this.editing = true;
            this.formData = { ...this.data };
        },
        saveChanges() {
            var formData = this.$data.formData;
            const { picture, ...rest } = formData;
            console.log(picture);
            const postData = { ...rest };

            axios.post(`${this.$config.apiUrl}/api/character/character`,
                postData,
                {
                    headers: {
                        'Authorization': `Bearer ${this.$store.state.token}`,
                        "Content-Type": "application/json"
                    }
                }).catch(error =>
                    console.error(error))
                .then(response => {
                    if (response.data.code == 200) {
                        console.log("character update!!!!");
                    }
                });

            if (this.uploadedFile) {
                this.uploadFile();
                this.uploadedFile = null;
            }

            this.editing = false;
            this.$emit('update:data', this.$data.formData);
        },
        cancelEdit() {
            this.editing = false;
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.uploadedFile = file;
            this.formData.picture = URL.createObjectURL(file)
        },
        uploadFile() {

            var bodyFormData = new FormData();
            bodyFormData.append('id', this.$data.formData.id);
            bodyFormData.append('file', this.$data.uploadedFile);
            axios.post(`${this.$config.apiUrl}/api/character/picture`,
                bodyFormData,
                {
                    headers: {
                        'Authorization': `Bearer ${this.$store.state.token}`
                    }
                }).catch(error =>
                    console.error(error))
                .then(response => {
                    if (response.data.code == 200) {
                        console.log('upload file');
                    }
                });

        }
    }
};
</script>
