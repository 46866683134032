import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.token || null,

  },

  mutations: {
    setToken(state, newToken) {
      state.token = newToken;
      localStorage.setItem('token', newToken);
    },

    delToken(state) {
      state.token = null;
      localStorage.removeItem('token');
    },
  },
  actions: {
  },
  modules: {
  }
})