<template>
    <h1>Something went wrong :(</h1>
</template>
<style scoped>
.books-list {
    display: grid;
    grid-template: repeat(3, minmax(0, 5fr)) / repeat(5, minmax(0, 5fr));
    grid-gap: 20px;
    margin: 0 auto;
}
</style>

<script>
export default {
    data() {
        return {
            token: "",
        };
    },
    mounted() {},
    methods: {},
};
</script>
