<template>
    <div class="pagination">
        <a href="#" @click="toPrevious">上一頁</a>&nbsp;
        <a
            href="#"
            @click="toFirst"
            v-bind:class="this.currentPage == 1 ? 'current-page' : ''"
            >1</a
        >
        <a href="#" v-if="this.headDotShow">...</a>
        <a
            href="#"
            @click="goTo(p)"
            v-bind:class="this.currentPage == p ? 'current-page' : ''"
            v-for="p in pageList"
            v-bind:key="p"
            >{{ p }}</a
        >
        <a href="#" v-if="this.tailDotShow">...</a>
        <a
            href="#"
            @click="toLast"
            v-bind:class="
                this.currentPage == this.pageCount ? 'current-page' : ''
            "
            >{{ this.pageCount }}</a
        >
        <a href="#" @click="toNext">下一頁</a>
    </div>
</template>

<style>
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination a.active {
    background-color: #4caf50;
    color: white;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}
</style>

<script>
export default {
    props: {
        pageCount: {
            type: Number,
        },
        currentPage: {
            type: Number,
        },
    },
    data() {
        return {
            selectedPage: this.currentPage,
        };
    },
    emits: ["setPage"],
    mouonted() {},
    computed: {
        headDotShow() {
            return this.currentPage > 4;
        },
        tailDotShow() {
            return parseInt(this.currentPage) + 3 < this.pageCount;
        },
        pageList() {
            var list = Array(5);
            for (let index = 0; index < 5; index++) {
                list[index] = this.currentPage - 2 + index;
            }

            return list.filter((index) => index > 1 && index < this.pageCount);
        },
    },
    methods: {
        goTo(page) {
            if (page == this.currentPage) {
                return;
            }

            this.$emit("setPage", page);
        },
        toPrevious() {
            let dest = this.currentPage - 1 > 1 ? this.currentPage - 1 : 1;
            this.goTo(dest);
        },
        toNext() {
            let dest =
                this.currentPage + 1 < this.pageCount
                    ? this.currentPage + 1
                    : this.pageCount;
            this.goTo(dest);
        },
        toFirst() {
            this.goTo(1);
        },
        toLast() {
            this.goTo(this.pageCount);
        },
    },
};
</script>
