<template>
    <section>
        <button
            v-on:click="changeShowed"
            class="tab"
            v-bind:class="[this.show_owned ? 'bg-primary text-white' : '']"
        >
            收的本本
        </button>
        <button
            v-on:click="changeShowed"
            class="tab"
            v-bind:class="[!this.show_owned ? 'bg-primary text-white' : '']"
        >
            關注中
        </button>

        <label for="searchQuery">收藏搜尋: </label>
        <input
            type="text"
            v-model="searchQuery"
            @input="searchOptions"
            placeholder="書名關鍵字"
        />

        <div class="container py-4">
            <div
                v-if="filteredOptions && filteredOptions.length > 0"
                class="books-list"
            >
                <BookLink
                    v-for="b in filteredOptions"
                    :key="`book-${b.book_id}`"
                    :book="b"
                />
            </div>
            <div v-else>
                <p>查無相關本本，請嘗試其他條件或</p>
            </div>
        </div>
    </section>
</template>
<style scoped>
.books-list {
    display: grid;
    grid-template: repeat(3, minmax(0, 5fr)) / repeat(5, minmax(0, 5fr));
    grid-gap: 20px;
    margin: 0 auto;
}
.tab {
    padding: 12px 20px;
    margin-right: 20px;
}
</style>
<script>
import axios from "axios";
import BookLink from "../components/BookLink.vue";

export default {
    data() {
        return {
            show_owned: true,
            books: {
                book_following: [],
                book_owned: [],
            },
            showed_books: [],
            filteredOptions: [],
            searchQuery: "",
            searchTimeout: null,
        };
    },
    mounted() {
        this.getData();
    },
    components: {
        BookLink,
    },
    methods: {
        getData() {
            axios
                .get(this.$config.apiUrl + "/api/user/collection")
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }

                    this.books = response.data.data;
                    this.books.book_following.forEach((b) => {
                        b.url = `/${b.url}`;
                    });
                    this.books.book_owned.forEach((b) => {
                        b.url = `/${b.url}`;
                    });
                    this.showed_books = this.books.book_owned;
                    this.filteredOptions = this.showed_books;
                    console.log(this.books);
                });
        },
        changeShowed() {
            this.show_owned = !this.show_owned;
            this.showed_books = this.show_owned
                ? this.books.book_owned
                : this.books.book_following;

            this.filteredOptions = this.showed_books;
        },
        searchOptions() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (this.searchQuery.length > 0) {
                    this.fetch();
                } else {
                    this.filteredOptions = this.showed_books;
                }
            }, 500);
        },
        fetch() {
            this.filteredOptions = this.showed_books.filter((item) => {
                return item.title.includes(this.searchQuery);
            });
        },
    },
};
</script>
