import { createRouter, createWebHistory } from 'vue-router'
import LayoutFront from '@/views/LayoutFront.vue'
import PageHome from '@/views/PageHome.vue'
import PageError from '@/views/PageError.vue'
import PageAbout from '@/views/PageAbout.vue'
import PageLogin from '@/views/PageLogin.vue'
import PageReader from '@/views/PageReader.vue'
import PageCreateBook from '@/views/PageCreateBook.vue'
import PageBook from '@/views/PageBook.vue'
import PageBuy from '@/views/PageBuy.vue'

import LayoutAdmin from '@/views/LayoutAdmin.vue'
import AdminHome from '@/views/AdminHome.vue'
import AdminCharacter from '@/views/AdminCharacter.vue'
import AdminUserCollections from '@/views/AdminUserCollections.vue'
import AdminUser from '@/views/AdminUser.vue'
import AdminMiPoint from '@/views/AdminMiPoint.vue'

const routes = [
    {
        path: '', name: 'Front', component: LayoutFront, children: [
            { path: '', name: 'PageHome', component: PageHome },
            { path: 'error', name: 'PageError', component: PageError },
            { path: 'about', name: 'PageAbout', component: PageAbout },
            { path: 'login', name: 'PageLogin', component: PageLogin },
            { path: 'reader/:id', name: 'PageReader', component: PageReader, props: true },
            { path: 'book/:id', name: 'PageBook', component: PageBook },
            { path: 'buy', name: 'PageBuy', component: PageBuy },
        ]
    },
    {
        path: '/admin', name: 'Admin', component: LayoutAdmin, children: [
            { path: '', name: 'AdminHome', component: AdminHome },
            { path: 'user', name: 'AdminUser', component: AdminUser },
            { path: 'user/collection', name: 'AdminUserCollections', component: AdminUserCollections },
            { path: 'character', name: 'AdminCharacter', component: AdminCharacter },
            { path: 'create', name: 'PageCreateBook', component: PageCreateBook },
            { path: 'mipoint', name: 'AdminMiPoint', component: AdminMiPoint }
        ]
    },
]
export const router = createRouter({
    history: createWebHistory(),
    routes
})
