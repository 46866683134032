<template>
    <section>
        <div class="max-w-4xl py-4 text-neutral-500">
            <div class="flex flex-col gap-5">
                <div>
                    <h1>我的房間</h1>
                </div>
                <div class="flex gap-4">
                    <div class="w-1/2" style="height: 250px"></div>
                    <div class="w-1/2 flex flex-col justify-between">
                        <div class="flex flex-col gap-3">
                            <div>
                                <div v-if="editing" class="flex gap-3">
                                    <p>使用者名稱：</p>
                                    <input
                                        type="text"
                                        v-model="name"
                                        @blur="blurOut"
                                    />
                                    <button @mousedown="changeName">
                                        儲存
                                    </button>
                                    <button @mousedown="cancelEdit">
                                        取消
                                    </button>
                                </div>
                                <div v-else class="flex gap-1">
                                    <p>使用者名稱：{{ name }}</p>
                                    <button @click="editName">
                                        <PencilIcon
                                            class="w-5 text-gray hover:text-primary"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div class="flex items-end gap-5 pl-2 pb-2">
                                <div class="portrait">
                                    <img v-if="newPicture" :src="newPicture" />
                                    <img
                                        v-else-if="picture"
                                        :src="
                                            'data:image/jpg;base64,' + picture
                                        "
                                    />
                                    <img v-else src="/img/user.jpg" />
                                </div>
                                <div>
                                    <div v-if="upload" class="flex gap-3">
                                        <input
                                            id="upload-portrait"
                                            type="file"
                                            @change="handleFileUpload"
                                            hidden
                                        />
                                        <label for="upload-portrait">
                                            <CameraIcon
                                                class="w-5 text-gray hover:text-primary"
                                            />
                                        </label>
                                        <button @click="uploadFile">
                                            儲存
                                        </button>
                                        <button @click="cancelUpload">
                                            取消
                                        </button>
                                    </div>
                                    <div v-else>
                                        <input
                                            id="upload-portrait"
                                            type="file"
                                            @change="handleFileUpload"
                                            hidden
                                        />
                                        <label for="upload-portrait">
                                            <CameraIcon
                                                class="w-5 text-gray hover:text-primary"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <p>帳號：{{ user.email }}</p>
                            <p>
                                我的咪幣：
                                <CurrencyDollarIcon class="icon-svg mr-2" />
                                {{ user.point }}
                            </p>
                            <div>
                                <p v-if="user.owned_books == null">已解封：0</p>
                                <p v-else>
                                    已解封：{{ user.owned_books.length }}
                                </p>
                            </div>
                            <p>
                                加入日期：{{ sliceDateTime(user.create_time) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.portrait {
    @apply w-16 h-16 rounded-full overflow-hidden;
}
</style>

<script>
import axios from "axios";
import PencilIcon from "@heroicons/vue/solid/PencilIcon";
import { CameraIcon, CurrencyDollarIcon } from "@heroicons/vue/outline";

export default {
    data() {
        return {
            user: {
                email: "",
                create_time: "",
            },
            name: null,
            editing: false,
            picture: null,
            newPicture: null,
            uploadPicture: null,
            upload: false,
            token: null,
        };
    },
    created() {
        this.token = this.$store.state.token;
        this.getData();
        this.getPicture();
    },
    components: {
        PencilIcon,
        CameraIcon,
        CurrencyDollarIcon,
    },
    methods: {
        getData() {
            axios
                .get(`${this.$config.apiUrl}/api/user/ownedbooks`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        let apiData = response.data.data;
                        this.$data.user = apiData;
                        this.name = apiData.user_name;
                    }
                });
        },
        sliceDateTime(dateTime) {
            return dateTime.slice(0, -3).replace("T", " ");
        },
        editName() {
            this.editing = true;
        },
        changeName() {
            this.name = this.name.trim();
            if (this.name != this.user.user_name && this.name.length > 0) {
                axios
                    .post(
                        `${this.$config.apiUrl}/api/user/username?name=${this.name}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                                "Content-Type":
                                    "application/json;charset=utf-8",
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 200) {
                            this.user.user_name = this.name;
                            console.log(response.data);
                        }
                    });
            } else {
                this.name = this.user.user_name;
            }
            this.editing = false;
        },
        cancelEdit() {
            this.name = this.user.user_name;
            this.editing = false;
        },
        blurOut() {
            if (this.editing) {
                this.name = this.user.user_name;
                this.editing = false;
            }
        },
        getPicture() {
            axios
                .get(`${this.$config.apiUrl}/api/user/picture`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.picture = response.data.data;
                    }
                });
        },
        handleFileUpload(event) {
            this.upload = true;
            const file = event.target.files[0];
            this.uploadedFile = file;
            this.newPicture = URL.createObjectURL(file);
        },
        uploadFile() {
            var formData = new FormData();
            formData.append("file", this.uploadedFile);
            axios
                .post(`${this.$config.apiUrl}/api/user/picture`, formData, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.picture = response.data.data;
                        console.log("upload file");
                    }
                });
            this.upload = false;
        },
        cancelUpload() {
            this.upload = false;
            this.newPicture = null;
        },
    },
};
</script>
