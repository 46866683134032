<template>
    <section>
        <div
            :id="$route.params.id"
            class="container max-w-5xl py-8 text-neutral-500"
        >
            <div v-if="isLoaded" class="flex gap-6">
                <div class="w-1/2">
                    <div class="book-cover">
                        <img
                            :src="'data:image/jpg;base64,' + book.cover_image"
                        />
                    </div>
                </div>
                <div class="w-1/2 flex flex-col justify-between">
                    <div>
                        <h1 class="text-2xl font-bold">{{ book.title }}</h1>
                        <h4>{{ book.author }}</h4>
                    </div>
                    <p>{{ book.introduction }}</p>
                    <div class="flex flex-col gap-2">
                        <p>
                            <TagIcon class="icon-svg mr-2" />
                            <span
                                v-for="tag in this.book.tags"
                                v-bind:key="tag.id"
                                >#{{ tag.name + " " }}</span
                            >
                        </p>
                        <p>
                            <DocumentDuplicateIcon class="icon-svg mr-2" />{{
                                book.page_count
                            }}
                            頁
                        </p>
                        <p>
                            <BookOpenIcon class="icon-svg mr-2" />實體書已完售
                        </p>
                        <p>
                            <CurrencyDollarIcon class="icon-svg mr-2" />{{
                                book.price
                            }}
                            咪幣
                        </p>
                    </div>
                    <div class="flex flex-col gap-4">
                        <div class="flex gap-4 items-end">
                            <router-link
                                :to="{
                                    name: 'PageReader',
                                    params: { id: book.id },
                                }"
                                class="btn primary-btn text-2xl"
                                :class="{ disable: !book.owned }"
                                >閱讀
                            </router-link>
                            <button
                                class="btn yellow-btn text-2xl"
                                @click="buy"
                                :class="{ disable: book.owned }"
                            >
                                解除封印
                            </button>
                        </div>
                        <div class="flex gap-6 text-xs">
                            <p>上架時間: {{ sliceDate(book.shelf_date) }}</p>
                            <p>本子誕生時間: {{ sliceDate(book.birthday) }}</p>
                        </div>
                    </div>
                </div>
                <div class="">
                    <button class="text-2xl mt-1" @click="follow">
                        <template v-if="book.is_following">
                            <StarIconSolid class="icon-svg mx-3" />
                            <p class="text-xs">取消關注</p>
                        </template>
                        <template v-else>
                            <StarIconOutline class="icon-svg mx-3" />
                            <p class="text-xs">關注</p>
                        </template>
                    </button>
                </div>
            </div>
            <div v-else class="flex gap-6 animate-pulse">
                <div class="w-1/2">
                    <div class="book-cover bg-gray-100"></div>
                </div>
                <div class="w-1/2 flex flex-col justify-between">
                    <div>
                        <div
                            class="w-full h-4 bg-gray-100 rounded-full mb-4"
                        ></div>
                        <div class="w-20 h-4 bg-gray-100 rounded-full"></div>
                    </div>
                    <div>
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-4 bg-gray-100 rounded-full"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div
                                        class="h-4 bg-gray-100 rounded-full col-span-2"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col gap-4">
                        <div class="w-28 h-4 bg-gray-100 rounded-full"></div>
                        <div class="w-28 h-4 bg-gray-100 rounded-full"></div>
                        <div class="w-28 h-4 bg-gray-100 rounded-full"></div>
                        <div class="w-28 h-4 bg-gray-100 rounded-full"></div>
                    </div>
                    <div class="flex flex-col gap-4">
                        <div class="flex gap-4 items-end">
                            <div class="btn primary-btn text-2xl disable">
                                閱讀
                            </div>
                            <div class="btn yellow-btn text-2xl disable">
                                解除封印
                            </div>
                        </div>
                        <div class="flex gap-6 text-xs">
                            <div
                                class="w-28 h-2 bg-gray-100 rounded-full mr-6"
                            ></div>
                            <div
                                class="w-28 h-2 bg-gray-100 rounded-full"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="w-14"></div>
            </div>
        </div>
    </section>
    <section>
        <PageComment :bookId="book.id" />
    </section>
</template>

<script>
import axios from "axios";
import PageComment from "@/components/PageComment.vue";
import StarIconOutline from "@heroicons/vue/outline/StarIcon";
import StarIconSolid from "@heroicons/vue/solid/StarIcon";
import {
    BookOpenIcon,
    TagIcon,
    DocumentDuplicateIcon,
    CurrencyDollarIcon,
} from "@heroicons/vue/outline";

export default {
    data() {
        return {
            book: {
                id: "000",
                title: "",
                author: "",
                introduction: "",
                birthday: "2000-01-01",
                is_sold_out: false,
                shelf_date: "2000-01-01",
                price: 9999,
                page_count: 99,
                cover_image: null,
                is_following: false,
                owned: false,
            },
            isLoaded: false,
            user: null,
            points: null,
        };
    },
    computed: {
        isSignedIn() {
            return this.$store.state.token != null;
        },
    },
    components: {
        StarIconOutline,
        StarIconSolid,
        BookOpenIcon,
        TagIcon,
        DocumentDuplicateIcon,
        CurrencyDollarIcon,
        PageComment,
    },
    methods: {
        async getData() {
            axios
                .get(`/api/reading/book?id=${this.$route.params.id}`)
                .then((response) => {
                    let apiData = response.data.data;
                    this.book = apiData;

                    this.isLoaded = true;
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async getUser() {
            axios.get("api/user").then((response) => {
                this.user = response.data.data;
            });
        },
        sliceDate(date) {
            return date.slice(0, 10);
        },
        follow() {
            this.book.is_following = !this.book.is_following;

            axios
                .post(`${this.$config.apiUrl}/api/user/addcollection`, {
                    book_id: this.book.id,
                    add: this.book.is_following,
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    this.book.is_following = !this.book.is_following;
                    console.log(error.message);
                });
        },
        buy() {
            axios
                .get(`${this.$config.apiUrl}/api/order/point`)
                .then((response) => {
                    this.points = response.data.data.points;

                    if (this.points > this.book.price) {
                        this.$swal({
                            text: `${this.book.price} 咪幣將從您的帳戶扣除\n是否確定繼續`,
                            type: "question",
                            showCancelButton: true,
                            confirmButtonText: "確定",
                            cancelButtonText: "取消",
                        }).then((confirm) => {
                            if (confirm.isConfirmed) {
                                this.addTransaction(this.book.price);
                                this.addOwnedBook();
                                this.book.owned = true;
                            }
                        });
                    } else {
                        this.$swal({
                            text: `帳戶餘額不足\n前往儲值頁面`,
                            type: "question",
                            showCancelButton: true,
                            confirmButtonText: "確定",
                            cancelButtonText: "取消",
                        }).then((confirm) => {
                            if (confirm.isConfirmed) this.$router.push("/pay");
                        });
                    }
                });
        },
        addTransaction(price) {
            axios
                .post(`${this.$config.apiUrl}/api/order/add`, {
                    transactionType: 1, // buy book
                    value: -1 * price,
                })
                .then((response) => {
                    console.log(response);
                });
        },
        addOwnedBook() {
            axios
                .post(`${this.$config.apiUrl}/api/user/addowned`, {
                    book_id: this.book.id,
                })
                .then((response) => {
                    console.log(response);
                });
        },
    },
    mounted() {
        this.getData();
        console.log("this.book :>> ", this.book);

        if (this.isSignedIn) {
            console.log("sign in");
            this.getUser();
        }
    },
    updated() {
        console.log("update");
    },
};
</script>
