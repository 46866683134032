<script>
export default {
    data() {
        return {};
    },
};
</script>

<template>
    <footer>
        <div class="footer">
            <div>
                <img src="/img/logo.png" class="img" />
                Copyright 2022 Comicomi <br />
                聯絡我們: <br />
                Email: zhiyujengwork@gmail.com
            </div>
        </div>
    </footer>
</template>

<style>
.footer {
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
    padding: 50px 0;
}
.img {
    margin: auto;
    padding-bottom: 20px;
}
</style>
