<template>
    <header>
        <section class="bg-gray-800 text-xs">
            <div class="container pt-2 pb-2 flex items-center justify-between">
                <h1 class="logo relative flex items-center gap-4">
                    <router-link to="/"
                        ><img src="/img/logo_w.png" class="w-20"
                    /></router-link>
                    <span class="text-white">管理系統</span>
                </h1>
                <div v-if="isLogin" class="flex gap-4 items-center">
                    <router-link to="/admin" class="text-gray">
                        <UserIcon class="w-4 inline-block mb-1" />
                        <span>暱稱五個字</span>
                    </router-link>
                </div>
                <div v-else class="flex gap-4 items-center">
                    <router-link
                        to="/login"
                        class="text-white bg-gray-800 px-3 py-1"
                    >
                        登入
                    </router-link>
                </div>
            </div>
        </section>
    </header>
    <main class="flex items-stretch">
        <nav class="bg-primary-light">
            <ul class="flex flex-col text-center">
                <div v-for="title in titles" v-bind:key="title.id">
                    <ExtendNavLink
                        :text="title.text"
                        :subtitles="title.subtitles"
                    />
                </div>
            </ul>
        </nav>
        <section class="grow py-2 px-4 bg-gray-100">
            <router-view />
        </section>
    </main>
</template>

<script>
import ExtendNavLink from "@/components/ExtendNavLink.vue";
import { UserIcon } from "@heroicons/vue/solid";
export default {
    components: {
        UserIcon,
        ExtendNavLink,
    },
    data() {
        return {
            isLogin: false,
            titles: [
                {
                    id: 1,
                    text: "我的設定",
                    subtitles: [
                        {
                            url: "/admin/user",
                            text: "帳號管理",
                        },
                        {
                            url: "/admin/user/collection",
                            text: "收的本本",
                        },
                    ],
                },
                {
                    id: 2,
                    text: "咪幣管理",
                    subtitles: [
                        {
                            url: "/admin/mipoint",
                            text: "我的咪幣",
                        },
                        {
                            url: "/buy",
                            text: "購買咪幣",
                        },
                    ],
                },
                {
                    id: 3,
                    text: "上架本本",
                    subtitles: [
                        {
                            url: "/admin/create",
                            text: "新增書本",
                        },
                        {
                            url: "/user/collection",
                            text: "已上傳書本一覽",
                        },
                        {
                            url: "/admin/character",
                            text: "角色管理",
                        },
                    ],
                },
                {
                    id: 4,
                    text: "販售管理",
                    subtitles: [
                        {
                            url: "/user",
                            text: "書本販售統計資料",
                        },
                        {
                            url: "/user",
                            text: "收款帳戶設定",
                        },
                    ],
                },
                {
                    id: 5,
                    text: "聯絡客服",
                    subtitles: [
                        {
                            url: "/about",
                            text: "常見問答",
                        },
                        {
                            url: "/about",
                            text: "客服聯絡方式",
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        // this.checkToken();
    },
    updated() {
        // this.checkToken();
    },
    methods: {
        checkToken() {
            let token =
                localStorage.getItem("token") || this.$store.state.token;
            if (token) {
                this.$store.commit("setToken", token);
                this.isLogin = true;
            } else {
                this.isLogin = false;
                this.$swal
                    .fire({
                        title: "需要登入",
                        text: "前往登入頁?",
                        icon: "question",
                        confirmButtonText: "是",
                        showDenyButton: true,
                        denyButtonText: "否",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push("/login");
                        }
                        // else if (result.isDenied) {
                        //   console.log('deny')
                        // }
                    });
            }
        },
    },
};
</script>
