<script>
export default {
  props: ['url', 'text'],
  data() {
    return {
    }
  }
}
</script>
<style lang="scss">
  .nav-link {
    transition: all 0.3s ease;
  }
  .nav-link.router-link-exact-active {
    @apply bg-gray-800;
  }
  
</style>

<template>
  <router-link :to="url" class="nav-link w-32 p-2 bg-primary text-white hover:bg-gray-800">{{text}}</router-link>
</template>