<template>
    <h2>角色設定</h2>
    <hr class="my-2">
    <div class="table">
        <div class="table-row">
            <div class="table-cell pl-4">
                <div v-if="characterList.length > 0">
                    <div>
                        <Card :data="selected" @update:data="handleCardUpdate"></Card>
                    </div>
                    <div class="flex gap-4 items-center">
                        <div>
                            選取角色：
                            <dropdown :options="characterList" :selected="selected" @updateOption="methodToRunOnSelect"
                            :placeholder="'選擇角色'">
                            </dropdown>
                        </div>
                        <button class="btn primary-btn"
                        @click="() => {
                            this.selected = {
                                id: 0,
                                name: '',
                                discription: '',
                                picture: null
                            };
                        }">新增角色</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from "axios";
import dropdown from "vue-dropdowns";
import Card from "@/components/CharacterCard.vue";

export default {

    data() {
        return {
            user: null,
            characterList: [
            ],
            selected: null
        };
    },
    created() {
        this.fetchCharacterData();
    },
    components: {
        dropdown,
        Card: Card
    },
    methods: {
        fetchCharacterData() {
            let token = localStorage.getItem("token") || this.$store.state.token;
            if (token) {
                this.$store.commit("setToken", token);
            }

            // dropdown list data
            axios.get(`${this.$config.apiUrl}/api/character/list`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .catch(error => {
                    console.error(error)
                    localStorage.removeItem("token")
                    this.$store.state.token = null
                })
                .then(response => {
                    if (response.data.code == 200) {
                        this.characterList = response.data.data;
                        this.selected = { ...this.characterList[0] };
                    }
                });
        },
        fetchUserData() {

            axios.get(`${this.$config.apiUrl}/api/user`, {
                headers: {
                    'Authorization': `Bearer ${this.$store.state.token}`
                }
            })
                .catch(error => {
                    console.log(error.response);
                    this.$router.push('/error')
                })
                .then(response => {
                    if (response.data.code == 200) {
                        this.characterList = response.data.data;
                        this.selected = { ...this.characterList[0] };
                    }
                });
        },
        methodToRunOnSelect(payload) {
            this.selected = payload;
        },
        getPicture(id) {
            // get character image
            axios.get(`${this.$config.apiUrl}/api/character/picture?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$store.state.token}`
                }
            })
                .catch(error =>
                    console.error(error))
                .then(response => {
                    if (response.data.code == 200) {
                        this.picture = response.data.data;
                    }
                });
        },
        handleCardUpdate(newData) {
            this.selected.name = newData.name;
        }
    }
};
</script>