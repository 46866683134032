<template>
    <div class="container">
        <ul class="progressbar">
            <li class="active">選擇購買方案</li>
            <li :class="{ active: step > 1 }">選擇付款方式</li>
            <li :class="{ active: step > 2 }">付款</li>
            <li :class="{ active: step > 3 }">完成</li>
        </ul>

        <div class="content">
            <div v-if="step == 1" class="step">
                <div v-for="c in caseList" v-bind:key="c.id" class="w-50">
                    <CommodityCase
                        :case="c"
                        :class="{ selected: c.isSelected }"
                        @update="
                            (n) => {
                                c.quantity = n;
                            }
                        "
                    />
                </div>
            </div>
            <div v-if="step == 2" class="step">
                <div class="cart">
                    <h1>訂單商品</h1>
                    <table>
                        <tr>
                            <th>商品</th>
                            <th>單價</th>
                            <th>數量</th>
                            <th>總價</th>
                        </tr>

                        <tr v-for="c in cart" v-bind:key="c.id">
                            <td>{{ c.name }}</td>
                            <td>{{ c.price }}</td>
                            <td>{{ c.quantity }}</td>
                            <td>{{ c.price * c.quantity }}</td>
                        </tr>
                    </table>
                    <div class="total-val">總金額：{{ this.totalPrice }}</div>
                </div>
            </div>
            <div v-if="step == 3">
                <h1 style="display: block">付款方式</h1>
                <div class="flex">
                    <input
                        type="radio"
                        name="pay-method"
                        id="line-pay"
                        checked
                    />
                    <label for="line-pay" style="margin-left: 20px">
                        <img :src="logo" />
                    </label>
                </div>
            </div>
            <div v-if="step == 4" class="step">
                <table>
                    <tr>
                        <th>商品</th>
                        <th>單價</th>
                        <th>數量</th>
                        <th>總價</th>
                    </tr>

                    <tr v-for="c in cart" v-bind:key="c.id">
                        <td>{{ c.name }}</td>
                        <td>{{ c.value }}</td>
                        <td>{{ c.quantity }}</td>
                        <td>+ {{ c.value * c.quantity }}</td>
                    </tr>
                </table>
                <div class="total-val">總金額：{{ this.points }}</div>

                <button @click="getTransactionHistory()">
                    Add transaction
                </button>
            </div>
            <div class="r-button">
                <button
                    v-if="step > 1"
                    class="button bg-primary text-white"
                    @click="this.step--"
                >
                    上一步
                </button>
                <button
                    v-if="step < 4"
                    class="button bg-primary text-white"
                    @click="nextStep()"
                >
                    下一步
                </button>
            </div>
        </div>
    </div>
    <div v-if="isLoading" id="loading-mask">
        <div class="preloader">
            <div class="c-three-dots-loader"></div>
        </div>
    </div>
    <button
        v-if="isLoading && !isLinePayConfirm"
        class="btn-manually"
        @click="addTransaction()"
    >
        手動 confirm
    </button>
</template>
<style scoped>
#loading-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: white;
    opacity: 0.5;
    z-index: 1000;
    overflow: hidden;
    top: 0;
}

#loading-mask h1 {
    text-align: center;
    color: black;
    padding-top: 50%;
    transform: translateY(-50%);
}

#loading-mask .preloader {
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    top: calc(50vh - 10px);
    left: calc(50vw - 10px);
}

#loading-mask .c-three-dots-loader {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation-fill-mode: both;
    animation: three-dots-loader-animation 2s infinite ease-in-out;
    animation-delay: -0.16s;
    color: #333;
}

#loading-mask .c-three-dots-loader:before,
#loading-mask .c-three-dots-loader:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    animation: three-dots-loader-animation 2s infinite ease-in-out;
    border-radius: 50%;
}

#loading-mask .c-three-dots-loader:before {
    left: -24px;
    animation-delay: -0.32s;
}

#loading-mask .c-three-dots-loader:after {
    left: 24px;
}

#loading-mask .load-mask-wrapper {
    position: absolute;
    text-align: center;
    height: 200px;
    width: 200px;
    top: calc(50vh - 100px);
    left: calc(50vw - 100px);
}

#loading-mask .load-mask-innerDots {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 100%;
    height: 100%;
}

#loading-mask .load-mask-outerPlane {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@keyframes three-dots-loader-animation {
    0%,
    80%,
    100% {
        box-shadow: 0 20px 0 -24px;
    }
    40% {
        box-shadow: 0 20px 0 0;
    }
}

.flex {
    display: flex;
}

.row {
    margin-top: 50px;
    margin-left: 50px;
}

h1 {
    font-size: x-large;
    margin-bottom: 30px;
}
.cart {
    width: 100%;
}
table {
    width: 100%;
    padding: 8px;
}
tr {
    width: 100%;
}
th,
td {
    padding: 4px 0;
    text-align: left;
    margin-right: 50px;
}
.total-val {
    text-align: right;
    margin: 50px 100px 0;
    color: red;
}
p {
    margin-left: 50px;
}
.left {
    margin-left: 50px;
}
.container {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    max-width: 1200px;
}
.content {
    margin: 0 100px;
    display: flex;
    flex-direction: column;
}
.step {
    display: flex;
    margin: 20px 0;
}
.r-button {
    margin: 50px 50px 0 0;
    display: flex;
    justify-content: flex-end;
}
.button {
    padding: 12px 32px;
    margin: 0 10px;
}
.btn-manually {
    padding: 12px 32px;
    border: 1px solid;
    position: absolute;
    color: black;
    margin-top: 50%;
    transform: translateY(-50%);
    top: 0;
    left: calc(50vw - 80px);
}
.progressbar li {
    position: relative;
    text-align: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}
.progressbar {
    counter-reset: step;
    margin: 50px auto;
    display: flex;
    justify-content: space-evenly;
    flex: 1;
}
.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid #bebebe;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
}
.progressbar li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background: #979797;
    top: 15px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child::after {
    content: none;
}
.progressbar li.active::after {
    background: #3aac5d;
}
.progressbar li.active::before {
    border-color: #3aac5d;
    background: #3aac5d;
    color: white;
}
</style>
<script>
/* eslint-disable */
import axios from "axios";
import logo from "@/assets/img/line_pay_logo.png";
import commodity from "@/components/CommodityCase.vue";

export default {
    data() {
        return {
            step: 1,
            caseList: [
                {
                    id: 1,
                    name: "550咪幣",
                    description: "買 500 送 50",
                    price: 500,
                    value: 550,
                    quantity: 0,
                },
                {
                    id: 2,
                    name: "1200咪幣",
                    description: "買 1000 送 200",
                    price: 1000,
                    value: 1200,
                    quantity: 0,
                },
            ],
            cart: [],
            linePayRequestBody: null,
            transactionId: "",
            logo: logo,
            linePayUrl: "",
            isLinePayConfirm: false,
            isLoading: false,
            points: 0,
        };
    },
    computed: {
        totalPrice() {
            return this.caseList.reduce(
                (accumulator, currentValue) =>
                    accumulator.quantity * accumulator.price +
                    currentValue.quantity * currentValue.price
            );
        },
        totalValue() {
            return this.caseList.reduce(
                (accumulator, currentValue) =>
                    accumulator.quantity * accumulator.value +
                    currentValue.quantity * currentValue.value
            );
        },
    },
    components: { CommodityCase: commodity },
    methods: {
        nextStep() {
            if (this.step == 1) {
                // check if quantity equal to 0
                let isEmpty = true;
                this.caseList.forEach((c) => {
                    if (c.quantity > 0) {
                        isEmpty = false;
                        this.cart.push(c);
                    }
                });
                if (isEmpty) {
                    this.$swal("必須選擇購買方案");
                    return;
                }
            } else if (this.step == 2) {
                this.generateLinePayRequestBody();
            } else if (this.step == 3) {
                this.sendLinePayRequest();
                this.isLoading = true;
                return;
            }
            this.step++;
        },
        generateLinePayRequestBody() {
            console.log("generate line pay request");
            new Promise((resolve) => {
                this.linePayRequestBody = {
                    amount: this.totalPrice,
                    currency: "TWD",
                    orderId: self.crypto.randomUUID(),
                    packages: [
                        {
                            id: "1",
                            amount: this.totalPrice,
                            products: [],
                        },
                    ],
                    redirectUrls: {
                        confirmUrl: "zyjeng.com",
                        cancelUrl: "zyjeng.com",
                    },
                };
                resolve();
            })
                .then(() => {
                    this.cart.forEach((c) => {
                        this.linePayRequestBody.packages[0].products.push({
                            id: c.id.toString(),
                            name: c.name,
                            quantity: c.quantity,
                            price: c.price,
                        });
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendLinePayRequest() {
            console.log("line pay request");
            axios
                .post(
                    `${this.$config.apiUrl}/api/line/pay`,
                    this.linePayRequestBody
                )
                .then((response) => {
                    if (
                        response.data.code == 200 &&
                        response.data.data.return_code == "0000"
                    ) {
                        this.linePayUrl =
                            response.data.data.info.payment_url.web;
                        this.transactionId =
                            response.data.data.info.transaction_id;

                        window.open(this.linePayUrl);
                    }
                });
        },
        sendConfirmRequest() {
            console.log("line confirm request");
            axios
                .post(`${this.$config.apiUrl}/api/line/confirm`, {
                    amount: this.totalPrice,
                    transaction_id: this.transactionId.toString(),
                })
                .then((response) => {
                    this.isLinePayConfirm = true;
                    if (
                        response.data.code == 200 &&
                        response.data.data.return_code == "0000"
                    ) {
                        this.isLoading = false;
                        this.step++;
                    }
                });
        },
        addTransaction() {
            console.log("add transaction");
            let requestBody = [];
            this.cart.forEach((c) => {
                requestBody.push({
                    transaction_type: 0,
                    commodity_id: c.id,
                    quantity: c.quantity,
                    price: c.price,
                    value: c.value * c.quantity,
                });
            });

            axios
                .post(`${this.$config.apiUrl}/api/order/addrange`, requestBody)
                .then((response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        this.sendConfirmRequest();
                    }
                });
        },
        confirmTask() {
            new Promise((resolve) => {
                this.addTransaction();
                resolve();
            })
                .then((res) => {
                    this.sendConfirmRequest();
                    res();
                })
                .then(() => {
                    console.log("then");
                    this.getPoints();
                })
                .catch(() => {
                    $swal("付款失敗");
                    window.location.reload();
                });
        },
        getPoints() {
            axios
                .get(`${this.$config.apiUrl}/api/order/point`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.points = response.data.data.points;
                    }
                });
        },
        getCommodities() {
            axios
                .get(`${this.$config.apiUrl}/api/order/commodity`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.caseList = response.data.data;
                    }
                });
        },
    },
    created: function () {
        this.getCommodities();
        axios
            .post(`${this.$config.apiUrl}/api/auth/validate`)
            .then((response) => {
                console.log(response);
                if (response.data.code == 200) {
                    return;
                }
            });

        window.addEventListener("visibilitychange", () => {
            if (document.visibilityState == "visible") {
                if (
                    this.isLoading &&
                    this.step == 3 &&
                    !this.isLinePayConfirm
                ) {
                    this.addTransaction();
                }
            }
        });
    },
};
</script>
