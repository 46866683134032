<template>
    <div>
        <button @click="InitDefaultValue">Create</button>
        <input
            type="text"
            v-model="searchTerm"
            @input="searchBook"
            placeholder="搜尋書名或作者名"
        />
        <table class="table-auto">
            <thead>
                <tr>
                    <th class="px-4">Action</th>
                    <th class="px-4">ID</th>
                    <th class="px-4">書名</th>
                    <th class="px-4">描述</th>
                    <th class="px-4">作者</th>
                    <th class="px-4">二創或原創</th>
                    <th class="px-4">分類</th>
                    <th class="px-4">標籤</th>
                    <th class="px-4">售價</th>
                    <th class="px-4">頁數</th>
                    <th class="px-4">是否有實體書庫存</th>
                    <th class="px-4">實體書購買管道</th>
                    <th class="px-4">出版日期</th>
                    <th class="px-4">上架狀態</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="book in searchResults" :key="book.id">
                    <td class="border px-4">
                        <button @click="editBook(book)" class="px-2">
                            Edit
                        </button>
                    </td>
                    <td class="border px-4">{{ book.id }}</td>
                    <td class="border px-4">{{ book.title }}</td>
                    <td class="border px-4">{{ book.introduction }}</td>
                    <td class="border px-4">
                        {{
                            this.characters.find((e) => e.id == book.author_id)
                                .name
                        }}
                    </td>
                    <td class="border px-4">{{ book.is_origin }}</td>
                    <td class="border px-4">
                        <span v-for="c in book.categories_id" :key="c">
                            {{
                                this.categories_list.find((cl) => cl.id == c)
                                    ?.name
                            }},
                        </span>
                    </td>
                    <td class="border px-4">
                        <span v-for="t in book.tags" :key="t.id">
                            {{ t.name }},
                        </span>
                    </td>
                    <td class="border px-4">{{ book.price }}</td>
                    <td class="border px-4">{{ book.page_count }}</td>
                    <td class="border px-4">
                        {{
                            this.is_valid_options.find(
                                (e) => e.value == book.is_valid
                            ).description
                        }}
                    </td>
                    <td class="border px-4">{{ book.where_to_buy }}</td>
                    <td class="border px-4">{{ book.shelf_date }}</td>
                    <td class="border px-4">{{ book.shelf_date }}</td>
                </tr>
            </tbody>
        </table>

        <div v-if="editingBook">
            <div class="container">
                <form @submit.prevent="saveBook" @keydown.enter.prevent>
                    <input
                        type="text"
                        v-model="editingBook.id"
                        v-bind:hidden="editingBook.id.length == 0"
                    />
                    <label class="block mb-2">封面圖:</label>
                    <img
                        v-if="this.previewImage"
                        :src="'data:image/jpg;base64,' + this.previewImage"
                        href="#"
                        alt="not availible"
                    />
                    <input
                        type="file"
                        @change="coverFileUpload"
                        class="px-4 bg-gray-200 text-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent mb-4"
                    />
                    <label class="block mb-2">內容:</label>
                    <input
                        type="file"
                        multiple
                        @change="contentsFileUpload"
                        class="px-4 bg-gray-200 text-gray-700 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent mb-4"
                    />

                    <label class="block mb-2">書名: 30字以內純文字</label>
                    <input
                        type="text"
                        v-model="editingBook.title"
                        :required="true"
                    />

                    <label class="block mb-2">描述: 300字以內純文字</label>
                    <textarea v-model="editingBook.introduction"></textarea>

                    <label class="block mb-2">作者:</label>
                    <select v-model="editingBook.author_id">
                        <option
                            v-for="item in characters"
                            :value="item.id"
                            :key="item.id"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                    <br />
                    <br />

                    二創或原創
                    <div>
                        <input
                            type="radio"
                            v-model="editingBook.is_origin"
                            :value="true"
                        />原創
                        <input
                            type="radio"
                            v-model="editingBook.is_origin"
                            :value="false"
                        />二創
                    </div>
                    <br />

                    分類 {{ editingBook.categories_id }}
                    <div>
                        <span
                            v-for="item in categories_list"
                            :key="item.id"
                            @click="selectCat(item)"
                        >
                            {{ item.name }}
                        </span>
                    </div>
                    <br />

                    <label class="block mb-2">標籤:</label>
                    <div>
                        <ul>
                            <li v-for="tag in editingBook.tags" :key="tag.id">
                                {{ tag.name }}
                            </li>
                        </ul>
                        <input
                            type="text"
                            v-model="tagSearchTerm"
                            @input="filterTags"
                            @keyup.enter="enterTag"
                            @click.stop
                            placeholder="Search..."
                        />
                        <ul v-if="tagSearchResult.length">
                            <li
                                v-for="tag in tagSearchResult"
                                :key="tag.id"
                                @click="selectTag(tag)"
                            >
                                {{ tag.name }}
                            </li>
                        </ul>
                    </div>

                    <label class="block mb-2">售價:</label>
                    <input
                        type="number"
                        v-model="editingBook.price"
                        :required="true"
                    />
                    <label class="block mb-2">頁數:</label>
                    <input
                        type="number"
                        v-model="editingBook.page_count"
                        :required="true"
                    />

                    <label class="block mb-2">上架時間</label>
                    <input type="date" v-model="this.editingBook.shelf_date" />
                    <span v-if="editingBook.shelf_date">
                        <input
                            type="button"
                            value="取消上架"
                            @click="
                                () => {
                                    this.editingBook.shelf_date = null;
                                }
                            "
                        />
                    </span>
                    <br />

                    <label class="block mb-2">出版日期:</label>
                    <input type="date" v-model="editingBook.birthday" />
                    <label class="block mb-2">是否有實體書庫存: </label>
                    <div
                        v-for="item in is_valid_options"
                        v-bind:key="item.value"
                    >
                        <input
                            type="radio"
                            class=""
                            v-model="editingBook.is_valid"
                            :value="item.value"
                        />
                        {{ item.description }}
                    </div>
                    18禁
                    <div>
                        <input
                            type="radio"
                            v-model="editingBook.is_r18"
                            :value="true"
                        />Yes
                        <input
                            type="radio"
                            v-model="editingBook.is_r18"
                            :value="false"
                        />No
                    </div>

                    <button type="submit" class="px-4 rounded">儲存</button>
                    <button type="button" @click="cancelEdit" class="px-4">
                        取消
                    </button>
                    <p>{{ validateMessage }}</p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
//import crypto from cry

export default {
    data() {
        return {
            searchTerm: "",
            characters: [],
            categories_list: [],
            is_valid_options: [
                { value: 0, description: "是" },
                { value: 1, description: "否" },
                { value: 2, description: "保密" },
                { value: 3, description: "無紙本書" },
            ],
            books: [],
            searchResults: [],
            editingBook: null,
            files: new FormData(),
            previewImage: "",
            tagSearchTerm: "",
            tagSearchResult: [],
            searchTimeout: null,
            isDropdownOpen: false,
            validateMessage: "",
        };
    },
    mounted() {
        let token = localStorage.getItem("token") || this.$store.state.token;
        if (token) {
            console.log("token:  ", token);
            this.$store.commit("setToken", token);
        } else {
            console.log("no token");
            this.$router.push("/login");
        }
        this.getUser();
        this.getCharacter();
        this.getcategories_id();
    },
    methods: {
        getUser() {
            axios
                .get(`${this.$config.apiUrl}/api/updatebook/getbyuser`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.books = response.data.data;
                    this.searchResults = response.data.data;
                    console.log(this.books[0]);
                })
                .catch((error) => {
                    if (error.response.status == "401") {
                        console.log("token not valid or expired");
                        this.$router.push("login");
                    }
                });
        },
        getCharacter() {
            axios
                .get(`${this.$config.apiUrl}/api/character/dropdown`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                    },
                })
                .then((response) => {
                    this.characters = response.data.data;

                    // 預設選第一個腳色
                    if (this.characters.length > 0)
                        this.editingBook.author_id = this.characters[0].id;
                })
                .catch((error) => {
                    if (error.response && error.response.status == "401") {
                        this.$router.push("login");
                    }
                });
        },
        getcategories_id() {
            axios
                .get(`${this.$config.apiUrl}/api/common/category`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.token}`,
                    },
                })
                .then((response) => {
                    this.categories_list = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status == "401") {
                        this.$router.push("login");
                    }
                });
        },
        searchBook() {
            this.searchResults = this.books.filter((book) => {
                const searchTerm = this.searchTerm.toLowerCase();
                return (
                    book.title.toLowerCase().includes(searchTerm) ||
                    this.characters
                        .find((e) => e.id == book.author_id)
                        .name.toLowerCase()
                        .includes(searchTerm)
                );
            });
        },
        async editBook(book) {
            this.editingBook = Object.assign({}, book);

            // get cover image preview
            let response = await axios.get(
                `${this.$config.apiUrl}/api/reading/cover`,
                {
                    params: { id: book.id },
                }
            );

            this.previewImage = response.data.data;

            window.scrollTo(0, document.body.scrollHeight);
        },
        saveBook() {
            this.validateForm();

            // 把編輯的資料塞回表格
            const index = this.books.findIndex(
                (book) => book.id === this.editingBook.id
            );
            this.books.splice(index, 1, this.editingBook);

            this.files.append("id", this.editingBook.id);

            // upload files
            axios
                .post(
                    `${this.$config.apiUrl}/api/create/upload/files`,
                    this.files,
                    {
                        headers: {
                            Authorization: `Bearer: ${this.$store.state.token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.files = new FormData();
                });

            // update book info
            axios
                .post(
                    `${this.$config.apiUrl}/api/updatebook/update`,
                    this.editingBook,
                    {
                        headers: {
                            "contents-Type": "application/json",
                            Authorization: `Bearer: ${this.$store.state.token}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.editingBook = null;
                });
        },
        validateForm() {
            if (!this.files.get("cover_image") || !this.files.get("contents")) {
                this.validateMessage = "image is required";
                return;
            }

            this.validateMessage = "";
            console.log("pass");
        },
        cancelEdit() {
            this.editingBook = null;
            this.files = new FormData();
        },
        coverFileUpload(event) {
            const files = event.target.files;
            this.files.append("cover_image", files[0]);

            console.log(this.files);
        },
        contentsFileUpload(event) {
            const files = event.target.files;
            for (var i = 0; i < files.length; i++) {
                this.files.append("contents", files[i]);
            }

            console.log(this.files);
        },
        filterTags() {
            this.isDropdownOpen = true;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (this.tagSearchTerm.length > 0) {
                    this.fetchTags();
                } else {
                    this.tagSearchResult = [];
                }
            }, 500);
        },
        async fetchTags() {
            try {
                const response = await fetch(
                    `${this.$config.apiUrl}/api/tag/search?term=${this.tagSearchTerm}`
                );
                const tags = await response.json();
                this.tagSearchResult = tags.data;
            } catch (error) {
                console.error(error);
            }
        },
        selectTag(tag) {
            this.tagSearchTerm = tag.name;
            this.tagSearchResult = [];
            this.enterTag();
        },
        async enterTag() {
            var tag = this.tagSearchTerm;

            // api add tag
            var response = await axios.post(
                `${this.$config.apiUrl}/api/tag/sync`,
                {
                    name: tag,
                }
            );

            var data = response.data.data;

            this.editingBook.tags.push({
                id: data.id,
                name: tag,
            });
            this.tagSearchTerm = "";
        },
        selectCat(item) {
            let index = this.editingBook.categories_id.indexOf(item.id);
            if (index !== -1) {
                this.editingBook.categories_id.splice(index, 1);
            } else {
                this.editingBook.categories_id.push(item.id);
            }
            console.log(this.editingBook.categories_id);
        },
        InitDefaultValue() {
            var today = new Date();
            var todayString = `${today.getFullYear()}-${(today.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${today
                .getDate()
                .toString()
                .padStart(2, "0")}`;
            let newId = crypto.randomUUID();

            this.editingBook = {
                id: newId,
                title: "QQQQ",
                introduction: "",
                author_id: 4,
                is_origin: false,
                categories_id: [],
                tags: [],
                price: null,
                page_count: null,
                is_valid: 0,
                where_to_buy: "",
                shelf_date: todayString,
                birthday: todayString,
                is_r18: false,
            };
        },
    },
};
</script>
