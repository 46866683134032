<template>
    <div>
        <PageNumber
            :currentPage="page"
            :pageCount="11"
            @setPage="
                (i) => {
                    page = i;
                }
            "
        />
    </div>
</template>
<script setup>
import { ref } from "vue";
import PageNumber from "@/components/PageNumber.vue";

const page = ref(1);
</script>
