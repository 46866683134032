<script>
import NavLink from "../components/NavLink.vue";
import { SearchIcon, UserIcon, CogIcon } from "@heroicons/vue/solid";

export default {
    data() {
        return {
            NavLink,
            keyword: "",
        };
    },
    components: {
        NavLink,
        SearchIcon,
        UserIcon,
        CogIcon,
    },
    computed: {
        isSignedIn() {
            return this.$store.state.token != null;
        },
    },
    methods: {
        submitSearch() {
            console.log("keyword  " + this.keyword);
            if (this.keyword) {
                this.$router.push({
                    name: "PageHome",
                    query: { keyword: this.keyword },
                });
            } else {
                this.$router.push({
                    name: "PageHome",
                });
            }
        },
        logout() {
            this.$store.commit("delToken");
            this.$router.go(0);
        },
    },
};
</script>
<style lang="scss" scoped>
.logo {
    .talk-box {
        display: inline-block;
        position: absolute;
        left: 100%;
        top: -1.6rem;
        padding: 4px 8px;
        white-space: nowrap;
        border-radius: 5px;
        @apply bg-gray-300 text-white;
    }

    .talk-box::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 15px 0 0px;
        top: calc(100% - 1px);
        left: 1rem;
        @apply border-transparent border-t-gray;
    }
}

.nav-link + .nav-link {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
</style>
<template>
    <header>
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
        <section>
            <div class="container pt-12 pb-4 flex items-end justify-between">
                <h1 class="logo relative">
                    <router-link to="/"
                        ><img src="/img/logo.png"
                    /></router-link>
                    <span class="talk-box">不用再擔心書櫃爆滿了</span>
                </h1>
                <div class="relative border border-gray pr-10">
                    <input
                        type="search"
                        v-model="keyword"
                        placeholder="請輸入搜尋關鍵字(EX:書名、作者名...)"
                        v-on:keyup.enter="submitSearch"
                        class="w-80 px-2 py-1"
                    />
                    <label
                        for="searchSubmit"
                        class="cursor-pointer absolute right-2 top-1"
                    >
                        <SearchIcon class="w-6 text-gray" />
                    </label>
                    <input
                        id="searchSubmit"
                        type="submit"
                        class="hidden"
                        @click="submitSearch"
                    />
                </div>
                <div v-if="isSignedIn" class="flex gap-4 items-center">
                    <router-link to="/admin/user" class="text-gray">
                        <UserIcon class="w-6 inline-block mb-1" />
                        <span>暱稱五個字</span>
                    </router-link>
                    <router-link to="/admin/user" class="text-gray">
                        <CogIcon class="w-6 inline-block mb-1" />
                    </router-link>
                    <button
                        @click="logout"
                        class="text-white bg-gray-800 px-3 py-1"
                    >
                        登出
                    </button>
                </div>
                <div v-else class="flex gap-4 items-center">
                    <router-link
                        to="/login"
                        class="text-white bg-gray-800 px-3 py-1"
                    >
                        登入
                    </router-link>
                </div>
            </div>
        </section>
        <section class="bg-primary-light">
            <div class="container">
                <nav class="flex text-center">
                    <NavLink url="/" text="首頁" />
                    <NavLink url="/about" text="網站介紹" />
                    <NavLink url="/admin/user/collection" text="收的本本" />
                    <NavLink url="/buy" text="儲值" />
                </nav>
            </div>
        </section>
    </header>
</template>
