<template>
    <GoogleLogin :callback="this.handleCredentialResponse" />
</template>
<script>
import axios from "axios";
import { GoogleLogin } from "vue3-google-login";

export default {
    name: "GoogleSignInButton",
    props: {
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
    },
    methods: {
        handleCredentialResponse: function (response) {
            let id_token = { id_token: response.credential };
            axios
                .post(this.$config.apiUrl + "/api/auth/google", id_token)
                .then((response) => {
                    if (response.data.code == "200") {
                        this.$store.commit(
                            "setToken",
                            response.data.data.token
                        );
                        console.log(this.$store.state.token);

                        this.$router.back();
                    } else {
                        console.log("登入失敗");
                    }
                })
                .catch((error) => console.log(error));
        },
    },
    components: { GoogleLogin },
};
</script>
