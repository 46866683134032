<template>
    <div v-if="book.url" class="book">
        <router-link :to="book.url" exact>
            <div class="book-cover">
                <img
                    v-if="book.title_image"
                    :src="'data:image/jpg;base64,' + book.title_image"
                />
            </div>
        </router-link>
        <router-link :to="book.url" exact>
            <h4 class="bold mt-2">{{ book.title }}</h4>
        </router-link>
        <h5 class="text-gray text-sm">{{ book.author }}</h5>
    </div>
    <div v-else class="book animate-pulse flex flex-col items-center gap-2">
        <div class="book-cover bg-gray-100 w-full"></div>
        <div class="w-full h-4 bg-gray-100 rounded-full"></div>
        <div class="w-12 h-3 bg-gray-100 rounded-full"></div>
    </div>
</template>
<script>
export default {
    props: ["book"],
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
.book {
    h4:hover {
        @apply text-primary underline;
    }

    h4 {
        font-weight: bold;
    }

    h4,
    h5 {
        @apply truncate;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
}
</style>
