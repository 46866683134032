<script>
import GoogleSignInButton from "@/components/GoogleSignInButton";
export default {
    name: "LoginPage",
    components: {
        GoogleSignInButton,
    },
    data() {
        return {
            token: this.$store.state.token,
        };
    },
    methods: {},
};
</script>
<template>
    <section>
        <div class="container mx-auto px-4">
            <h2>這裡是登入頁</h2>
            <GoogleSignInButton></GoogleSignInButton>
        </div>
    </section>
</template>
