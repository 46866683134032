<template>
    <div
        :id="$route.params.id"
        class="container max-w-5xl py-8 text-neutral-500"
    >
        <div class="flex flex-col gap-2">
            <p><ChatAlt2Icon class="icon-svg mx-2" />留言區</p>
            <div
                class="border list-group"
                style="height: 250px; overflow-y: scroll"
            >
                <div v-if="isLoaded">
                    <div v-if="isInsert">
                        <div class="flex items-center mx-1">
                            <p>
                                <UserIconOutline class="icon-svg" />{{
                                    newComment.user_name
                                }}
                            </p>
                            <small class="mx-5">{{
                                sliceDateTime(newComment.record_time)
                            }}</small>
                        </div>
                        <div class="mx-1 p-1">
                            <p>{{ newComment.content }}</p>
                        </div>
                        <hr />
                    </div>
                    <div
                        v-for="comment in bookComments"
                        :key="comment.id"
                        :comment="comment"
                        class="p-1"
                    >
                        <div class="flex items-center mx-1">
                            <p>
                                <UserIconOutline class="icon-svg" />{{
                                    comment.user_name
                                }}
                            </p>
                            <small class="mx-5">{{
                                sliceDateTime(comment.record_time)
                            }}</small>
                        </div>
                        <div class="mx-1 p-1">
                            <p>{{ comment.content }}</p>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-between">
                <div class="border p-1">
                    <PencilAltIcon class="icon-svg mx-2" />
                    <input
                        type="text"
                        v-model="content"
                        placeholder="Your Comments"
                    />
                </div>
                <button @click="addComment" class="btn primary-btn">
                    <CheckIcon class="icon-svg mx-3" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { CheckIcon, ChatAlt2Icon, PencilAltIcon } from "@heroicons/vue/solid";
import UserIconOutline from "@heroicons/vue/outline/UserIcon";

export default {
    props: ["bookId"],
    data() {
        return {
            bookComments: null,
            isLoaded: false,
            content: null,
            token: this.$store.state.token,
            newComment: "",
            isInsert: false,
        };
    },
    components: {
        CheckIcon,
        ChatAlt2Icon,
        PencilAltIcon,
        UserIconOutline,
    },
    methods: {
        getComments() {
            axios
                .get(
                    `${this.$config.apiUrl}/api/comment/get?bookId=${this.$route.params.id}`
                )
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }

                    let apiData = response.data.data;
                    this.$data.bookComments = apiData;
                    this.isLoaded = true;
                    //console.log(apiData);
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        async addComment() {
            if (this.content) {
                var today = new Date();
                var todayString = `${today.getFullYear()}-${(
                    today.getMonth() + 1
                )
                    .toString()
                    .padStart(2, "0")}-${today
                    .getDate()
                    .toString()
                    .padStart(2, "0")} ${today
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${today
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`;
                await axios
                    .post(`${this.$config.apiUrl}/api/comment/add`, {
                        book_id: this.bookId,
                        content: this.content,
                        record_time: todayString,
                    })
                    .then((response) => {
                        let apiData = response.data.data;
                        this.$data.newComment = apiData;
                        this.isInsert = true;
                        //console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.content = null;
                    });
            }
        },
        sliceDateTime(dateTime) {
            if (!dateTime) return;
            return dateTime.slice(0, -3).replace("T", " ");
        },
    },
    mounted() {
        this.getComments();
    },
};
</script>
