<script>
import NavLink from '../components/NavLink.vue';

export default {
  props: ['text', 'subtitles'],
  data() {
    return {
      isExpand: false
    };
  },
  components: { NavLink }
}
</script>
<style lang="scss">
.nav-link {
  transition: all 0.3s ease;
}

.nav-link.router-link-exact-active {
  @apply bg-gray-800;
}

.expend-wrapper {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <div @click="() => { this.isExpand = !this.isExpand }"
    class="nav-link w-32 p-2 bg-primary text-white hover:bg-gray-800">{{ text
    }}</div>
  <div v-if="isExpand" class="expend-wrapper">
    <NavLink v-for="sub in subtitles" v-bind:key="sub.text" :url="sub.url" :text="sub.text" class="bg-primary-light" />
  </div>
</template>