<template>
    <div>餘額：{{ this.miPoints }}</div>
    <table>
        <tr>
            <th>交易類型</th>
            <th>咪幣</th>
            <th>時間</th>
        </tr>
        <tr v-for="order in orderList" :key="order.id">
            <td>{{ order.transaction_type == 0 ? "買點數" : "買書" }}</td>
            <td>{{ order.mi_value }}</td>
            <td>
                {{
                    new Date(order.create_time).getFullYear() +
                    "-" +
                    new Date(order.create_time).getMonth() +
                    1 +
                    "-" +
                    new Date(order.create_time).getDay() +
                    " " +
                    new Date(order.create_time).getHours() +
                    ":" +
                    new Date(order.create_time).getMinutes()
                }}
            </td>
        </tr>
    </table>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            miPoints: 0,
            orderList: [],
        };
    },
    methods: {
        getMiPoints() {
            axios
                .get(`/api/order/point`)
                .then((response) => {
                    this.miPoints = response.data.data.points;
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        getOrderList() {
            axios
                .get(`/api/order/latest`)
                .then((response) => {
                    this.orderList = response.data.data;
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.getMiPoints();
        this.getOrderList();
    },
};
</script>
