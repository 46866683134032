<template>
    <section>
        <div class="container py-4">
            <div class="flex gap-6">
                <a
                    href="#"
                    v-for="t in orderTypes"
                    :key="`orderby-${t.id}`"
                    @click="changeOrder(t.id)"
                    :class="{ 'active text-primary': order.by === t.id }"
                    >{{ t.name }}
                    <ArrowDownIcon
                        v-show="order.by === t.id && !order.asc"
                        class="w-3 inline-block"
                    />
                    <ArrowUpIcon
                        v-show="order.by === t.id && order.asc"
                        class="w-3 inline-block"
                    />
                </a>
            </div>
        </div>
    </section>
    <section hidden>
        <span
            v-for="c in categories"
            :key="`categories-${c.id}`"
            :class="{ 'text-primary': c.selected }"
            @click="
                () => {
                    c.selected = !c.selected;
                }
            "
            >{{ c.name + " " }}
        </span>
    </section>
    <section hidden>
        <span
            v-for="t in tags"
            :key="`tags-${t.id}`"
            :class="{ 'text-primary': t.selected }"
            @click="
                () => {
                    t.selected = !t.selected;
                }
            "
            >{{ t.name + " " }}</span
        >
    </section>
    <button @click="getData" hidden>依分類查詢</button>
    <section>
        <div class="container py-4">
            <div class="books-list">
                <BookLink
                    v-for="book in books"
                    :key="`book-${book.id}`"
                    :book="book"
                />
            </div>
            <div v-if="books && books.length == 0">
                <p>
                    查無相關本本，請嘗試其他條件或<router-link to="/"
                        >回到首頁</router-link
                    >
                </p>
            </div>
            <div v-if="!books">
                <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                Processing...
            </div>
        </div>
    </section>
    <PageNumber
        :currentPage="this.order.page"
        :pageCount="this.totalPageCount"
        @setPage="updateValue"
    />
</template>
<style scoped>
.books-list {
    display: grid;
    grid-template: repeat(3, minmax(0, 5fr)) / repeat(5, minmax(0, 5fr));
    grid-gap: 20px;
    margin: 0 auto;
}
</style>

<script>
import BookLink from "../components/BookLink.vue";
import PageNumber from "@/components/PageNumber.vue";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/vue/solid";
import axios from "axios";
//import { ref } from "vue";
//import { copyWithin } from 'core-js/core/array';

export default {
    setup() {
        // const page = ref(1);
    },
    data() {
        return {
            books: null,
            isLoaded: false,
            orderTypes: [
                { id: 1, name: "熱門" },
                { id: 0, name: "上架時間" },
                { id: 2, name: "出版時間" },
                { id: 3, name: "價錢" },
                { id: 4, name: "解封人次" },
            ],
            order: {
                by: 0,
                asc: false,
                page: 1,
            },
            categories: [],
            tags: [],
            totalPageCount: 1,
            pageCapacity: 20,
        };
    },
    components: {
        BookLink,
        ArrowDownIcon,
        ArrowUpIcon,
        PageNumber,
    },
    computed: {},
    watch: {
        $route(to) {
            this.checkRouter(to);
        },
        order: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },
    methods: {
        getData() {
            let order = this.$data.order;
            let selectedCategories = this.categories.filter(
                (c) => c.selected === true
            );
            let selectedCatId = selectedCategories.map((x) => x.id);

            let selectedTags = this.tags.filter((c) => c.selected === true);
            let selectedTagIds = selectedTags.map((x) => x.id);
            let p = {
                orderby: order.by,
                isAsc: order.asc,
                page: order.page,
                cat:
                    selectedCategories.length > 0
                        ? selectedCatId.join(",")
                        : null,
                tag: selectedTags.length > 0 ? selectedTagIds.join(",") : null,
            };
            console.log(p);

            axios
                .get(this.$config.apiUrl + "/api/home/books", {
                    params: {
                        orderby: order.by,
                        isAsc: order.asc,
                        page: order.page,
                        cat:
                            selectedCategories.length > 0
                                ? selectedCatId.join(",")
                                : null,
                        tag:
                            selectedTags.length > 0
                                ? selectedTagIds.join(",")
                                : null,
                    },
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                })
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }

                    let apiData = response.data.data;
                    this.books = apiData;
                    this.books.forEach((b) => (b.url = `book/${b.id}`));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getSearchResult(keyword) {
            let order = this.order;
            const _t = this;

            axios
                .get(_t.$config.apiUrl + "/api/home/search", {
                    params: {
                        orderby: order.by,
                        isAsc: order.asc,
                        keyword: keyword,
                    },
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                })
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }
                    let apiData = response.data.data;
                    _t.books = apiData;
                    _t.books.forEach((b) => (b.url = `book/${b.id}`));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getCategories() {
            axios
                .get(this.$config.apiUrl + "/api/common/category", {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                })
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }

                    this.categories = response.data.data;
                    this.categories.forEach((c) => (c.selected = false));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getTags() {
            axios
                .get(this.$config.apiUrl + "/api/common/tags", {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                })
                .then((response) => {
                    if (response.status != "200") {
                        return;
                    }

                    this.tags = response.data.data;
                    this.tags.forEach((c) => (c.selected = false));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getPageCount() {
            axios
                .get(this.$config.apiUrl + "/api/home/pages")
                .then((response) => {
                    let count = response.data.data;
                    this.totalPageCount = Math.ceil(count / this.pageCapacity);
                    console.log(this.totalPageCount);
                });
        },
        changeOrder(orderby) {
            this.books = null;

            let order = this.$data.order;
            if (order.by === orderby) order.asc = !order.asc;
            else {
                order.by = orderby;
                order.asc = false;
            }

            this.getData();
        },
        checkRouter(router) {
            if (router.query.keyword) {
                this.getSearchResult(router.query.keyword);
            } else {
                this.getData();
            }
        },
        updateValue(val) {
            this.order.page = val;
        },
    },
    created() {
        this.checkRouter(this.$route);
        this.getCategories();
        this.getTags();
        this.getPageCount();
    },
};
</script>
